/* -------------------------------------------------------------------------------------------------------------------
                                                  General elements
------------------------------------------------------------------------------------------------------------------- */
* {
  box-sizing: border-box;
}

body {
  font-family: $open_sans;
  @include font(22px);
  background: #fff;
  @include break($medium) {
    font-size: 18px;
  }
  @include break($mobile) {
    font-size: 16px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  padding-bottom: 10px;
}

a {
  color: $color_link;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    outline: none;
    text-decoration: none;
    color: $color_hover;
  }
  &:focus {
    outline: none;
  }
  &[href^='tel:'] {
    cursor: default;
    color: inherit;
    text-decoration: none;
  }
}

strong {
  font-weight: 700;
}

iframe {
  max-width: 100%;
}

blockquote {
  color: #39414c;
  padding: 0 0 30px 70px;
  font-style: italic;
  position: relative;
  line-height: 1.7;
  &::before {
    content: "\f10d";
    font-style: normal;
    font-family: $font_awesome;
    position: absolute;
    top: -5px;
    font-size: 40px;
    left: 5px;
    color: #e5e5e5;
  }
  strong {
    font-style: normal;
  }
}

.clear {
  @include clearfix();
}

/* Buttons & Links
------------------------------------------------------------ */
// Default button.
.btn,
.button {
  @include font(16px, 1, #fff, 700);
  display: inline-block;
  border: none;
  padding: .9em 1.5em;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  background: $orange;
  outline: none;
  text-align: center;
  @include break($medium) {
    font-size: 15px;
  }
  @include break($mobile) {
    font-size: 14px;
  }
  &:hover,
  &:active {
    color: #fff;
    background: #ff8a22;
    text-decoration: none;
  }
  &:active {
    top: 2px;
  }
  // Spacing if more than 1 item.
  + a {
    margin-left: 10px;
  }
}

// Custom button.
.btn-custom {
  font-family: $playfair;
  @include font(16px, 1, #fff, 700);
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  padding: 16px 30px;
  background: $pink;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
  margin-right: 50px;
  &::after {
    content: '';
    display: block;
    background: $pink;
    height: 36px;
    width: 36px;
    position: absolute;
    top: 6px;
    right: -15px;
    border-radius: 4px;
    transform: rotate(45deg);
  }
  @include break($large) {
    font-size: 14px;
    margin-right: 30px;
    padding: 12px 25px 12px 15px;
    &::after {
      height: 29px;
      width: 29px;
      top: 5px;
      right: -10px;
    }
  }
  &:hover {
    background: #666;
    color: #fff;
    &::after {
      background: #666;
    }
  }
}

// Primary link.
.link,
.details {
  color: $blue;
  font-style: italic;
  font-size: 16px;
  position: relative;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  padding-bottom: 2px;
  // Underline transition effects.
  &:after,
  &:before {
    content: "";
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    position: absolute;
    transition: all 0.5s ease;
  }
  &:after {
    background: #aaa;
    transform: translate(0, 0);
  }
  &:before {
    background: $blue;
    transform: translate(300px, 0);
  }
  &:hover {
    color: $blue;
    text-decoration: none;
    &:after {
      transform: translate(300px, 0);
    }
    &:before {
      transform: translate(0, 0);
    }
  }
}

// Custom link.
.more {
  //font-family: $playfair;
  @include font(22px, 1.1, $light_blue, 700);
  display: inline-block;
  text-decoration: underline;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, .5);
  position: relative;
  &::before {
    content: '';
    background: url("../images/icon-arrow.png");
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 10px;
  }
  @include break($medium) {
    font-size: 20px;
  }
  @include break($mobile) {
    font-size: 18px;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

/* Default lists */
.list ul,
.entry-content ul,
ul.list {
  li {
    padding: 0 0 14px 40px;
    position: relative;
    font-size: 22px;
    font-weight: 600;
    background: url("../images/icon-list.png") no-repeat 0 5px;
    @include break($medium) {
      font-size: 18px;
    }
    @include break($mobile) {
      font-size: 16px;
    }
    ul {
      margin: 15px 0 0 0;
      li {
        font-size: 18px;
      }
    }
    a {
    }
  }
}

.not-home .list li {
  background: none;
  padding-left: 35px;
  &::before {
    content: '';
    display: block;
    @include circle(9px, $orange);
    position: absolute;
    top: 10px;
    left: 4px;
  }
  @include break($medium) {
    padding-left: 30px;
    &::before {
      top: 8px;
    }
  }
  @include break($mobile) {
    padding-left: 25px;
    &::before {
      top: 6px;
    }
  }
  li {
    padding-left: 25px;
    &::before {
      top: 8px;
    }
  }
}

/* Numbered list */
.list ol,
.entry-content ol,
ol.list {
  list-style: inside decimal;
  padding-bottom: 10px;
  li {
    padding-left: 0;
    line-height: 1.5;
    padding-bottom: 10px;
    ol {
      font-size: 13px;
      padding: 6px 0 6px 14px;
    }
  }
  .text-indent {
    padding-left: 35px;
  }
}

/* Back to top main button.
----------------------------------------------------------- */
#back_to_top {
  position: fixed;
  right: 10px;
  bottom: 47px;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: none;
  font-size: 25px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  z-index: 4;
  transition: .2s;
  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  .touch & {
    display: none;
    z-index: -1;
  }
}

/* Default classes.
------------------------------------------------------------ */
.wrapper {
  @include clearfix();
}

.spacer {
  clear: both;
  float: none;
  height: 20px;
}

/* Form fields.
------------------------------------------------------------ */
.site {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='url'],
  input[type='search'],
  input[type='date'],
  textarea,
  select {
    font-family: $open_sans;
    @include font(16px, 1, #000, 700);
    outline: none;
    background: #fff;
    box-sizing: border-box;
    border: none;
    display: block;
    padding: 8px 15px;
    width: 100%;
    border-radius: 5px;
    &:hover {
      //border-color: #451336;
    }
    &:focus {
      //border-color: #451336;
    }
    @include break($mobile) {
      font-size: 14px;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='url'],
  input[type='search'] {
  }

  textarea {
    max-height: 90px;
    padding: 15px;
    overflow: auto;
  }

  select {
    padding: 4px 5px;
    height: 32px;
  }

}

/* Tables inside content pages.
------------------------------------------------------------ */
article,
.table {
  table {
    width: 100%;
    margin-bottom: 30px;
    tr {
      background: #ededed;
      border-bottom: 1px solid #fff;
      &:nth-child(2n+1) {
        background: #f7f7f7;
      }
      &:hover {
        background: darken($white_light, 10%);
      }
      > *:first-child {
        text-align: left;
        border-left: none;
      }
    }
    th {
      font-family: $playfair;
      padding: 5px 5px 5px 20px;
      font-size: 18px;
      font-weight: 600;
      border-left: 1px solid #fff;
      color: #fff;
      background: $color_link;
      @include break($x-small) {
        padding-left: 10px;
        font-size: 15px;
        white-space: nowrap;
      }
    }
    td {
      font-size: 14px;
      color: #202020;
      border-left: 1px solid #fff;
      padding: 5px 5px 5px 20px;
    }
  }
}

div.table {
  overflow-x: auto;
  margin-bottom: 30px;
}

/* Images.
------------------------------------------------------------ */
.wp-post-image {
}

.alignleft {
  float: left;
  display: block;
  margin: 0 25px 10px 0;
  @include break($neutral) {
    float: none;
    display: block;
    margin: 0 auto 15px;
  }
}

.alignright {
  float: right;
  margin: 0 0 10px 15px;
  @include break($neutral) {
    float: none;
    display: block;
    margin: 0 auto 15px;
  }
}

.aligncenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

img.border {
  padding: 5px;
  border: 1px solid #c3c3c3;
  border-radius: 2px;
}

.featured-thumbnail {
  figcaption {
    margin-top: 10px;
  }
}

/* Page layouts.
------------------------------------------------------------ */

/* Titles
------------------------------------------------------------ */
h1 {
  @include font(40px, 1);
  font-family: $playfair;
  margin-bottom: 1em;
  @include break($large) {
    font-size: 42px;
  }
  @include break($medium) {
    font-size: 36px;
  }
  @include break($neutral) {
    font-size: 32px;
  }
  @include break($mobile) {
    font-size: 28px;
  }
  em {
    font-style: normal;
    font-size: 1.4em;
  }
  &.smaller-text {
    font-size: 1.6em;
    @include break($medium) {
      font-size: 1.4em;
    }
    @include break($neutral) {
      font-size: 1.1em;
    }
  }
}

h2 {
  text-align: center;
  font-family: $playfair;
  @include font(42px, 1.3);
  @include break($large) {
    font-size: 38px;
  }
  @include break($medium) {
    font-size: 33px;
  }
  @include break($mobile) {
    font-size: 21px;
  }
  &::after {
    content: '';
    display: block;
    width: 190px;
    height: 3px;
    background: #d6d6d6;
    margin: 15px auto 25px;
  }
  em {
    font-style: normal;
    font-size: 1.4em;
  }
  &.small {
    font-size: 36px;
    @include break($large) {
      font-size: 33px;
    }
    @include break($medium) {
      font-size: 27px;
    }
    @include break($mobile) {
      font-size: 24px;
    }
  }
}

h3 {
  font-family: $playfair;
  @include font(40px, 1.2, #000);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1em;
  @include break($medium) {
    font-size: 33px;
  }
  @include break($mobile) {
    font-size: 26px;
  }
  em {
    font-style: normal;
    font-size: 1.4em;
  }
}

h4 {
  @include font(24px, 1.3, #000, 700);
  margin-bottom: 1em;
  @include break($medium) {
    font-size: 20px;
  }
  @include break($mobile) {
    font-size: 18px;
  }
}

h5 {
  @include font(28px, 1.2, #000, 700);
  margin-bottom: 1em;
  @include break($medium) {
    font-size: 24px;
  }
  @include break($mobile) {
    font-size: 18px;
  }
}

h6 {
  @include font(1em, 1.2, #000, 700);
  display: inline-block;
  margin-bottom: 0;
}
