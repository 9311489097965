/* General site layout controls.
------------------------------------------------------------ */
.site-all {

}

#content {
  position: relative;
  z-index: 1;
}

.site-content {
  position: relative;
  clear: both;
}

// Pages with right sidebar.
.page-template-page-sidebar-right,
.page-template-page-counter {
  .site-content {
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 35%;
      top: 0;
      background: #f0f0f0;
      pointer-events: none;
      @include break($neutral) {
        display: none;
      }
    }
  }
}

.main-column {
  @include clearfix();
  .not-home & {
    padding: 4% 0 6%;
  }
}

// Mobile rules.
@include break($mobile) {
  // Spacing below repeated columns.
  [class*='col-'] {
    margin-top: 20px;
    &:first-child,
    &.no-spacing {
      margin-top: 0;
    }
  }

}

/* -------------------------------------------------------------------------------------------------------------------
                                                 Default Components
------------------------------------------------------------------------------------------------------------------- */
// Article info: date, author, category, tag.
.post-meta {
  font-size: 13px;
  margin-bottom: 15px;
  font-weight: 300;
  .fa {
    font-size: 14px;
  }
  time {
    font-style: italic;
  }
  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .tags {
    padding-top: 5px;
  }
}

/* Site preloader.
------------------------------------------------------------ */
.site-preloader {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: $white_light url("../images/balls.svg") no-repeat 50% 50%;
  text-align: center;
  // Enable preloader for mobile only.
  @include break($not-mobile) {
    display: none;
  }
  .loaded & {
    opacity: 0;
    z-index: -1;
  }
}

/* FlexSlider core styles.
------------------------------------------------------------ */
.ml-slider {
  .flexslider {
    position: relative;
    //overflow: hidden;
  }

  .slides {
    img {
      width: 100%;
      display: block;
      -moz-user-select: none;
      height: auto;
    }
  }

  .flex-pauseplay span {
    text-transform: capitalize;
  }

  /* Clearfix for the .slides element */
  .slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  html[xmlns] .slides {
    display: block;
  }

  * html .slides {
    height: 1%;
  }

  /* No JavaScript Fallback */
  /* If you are not using another script, such as Modernizr, make sure you
   * include js that eliminates this class on page load */
  .no-js .slides > li:first-child {
    display: block;
  }
}

/* 404 Page
------------------------------------------------------------ */

/* Post Navigation (prev/next buttons).
------------------------------------------------------------ */
body .posts-navigation {
  overflow: hidden;
  a {
    line-height: 30px;
    text-transform: uppercase;
    text-decoration: none;
    color: #155eb0;
    display: inline-block;
    padding: 0 12px;
    background: #efefef;
    font-weight: 700;
    font-size: 14px;
    i {
      transition: .3s;
      position: relative;
      font-size: 19px;
      line-height: 30px;
    }
    &[rel='prev'] {
      i {
        transform: translateX(-2px);
      }
      &:hover i {
        transform: translateX(-7px);
      }
    }
    &[rel='next'] {
      i {
        transform: translateX(2px);
      }
      &:hover i {
        transform: translateX(7px);
      }
    }
    &:last-child {
      float: right;
    }
    &:hover {
      background: #155eb0;
      color: #fff;
    }
  }
}

/* Post Pagination.
------------------------------------------------------------ */
.pagination-custom {
  overflow: hidden;
  @include break($mobile) {
    text-align: center;
  }
  > * {
    display: inline-block;
    font-family: $playfair;
    font-size: 12px;
    color: #666;
    line-height: 28px;
    margin: 0 5px 5px 0;
    text-align: center;
    width: 30px;
    height: 30px;
    border: 1px solid #e3e3e3;
    text-decoration: none;
    background: #fff;
  }
  a {
    color: #666;
    i {
      position: relative;
    }
    &[rel='prev'] {
      &:hover i {
        transform: translateX(-10px);
        color: #eee;
      }
    }
    &[rel='next'] {
      &:hover i {
        transform: translateX(10px);
        color: #eee;
      }
    }
    &.custom {
      width: auto;
      padding: 0 10px;
    }
    &:hover {
      color: #fff;
      background: #404040;
      border-color: #404040;
      text-decoration: none;
    }
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
  span.current {
    color: #fff;
    background: #404040;
    border-color: #404040;
  }
}

/* Search results.
------------------------------------------------------------ */
.search-results {
  .archive-description {
    display: block;
  }
  article {
    border-bottom: 1px dotted #bbb;
    margin-bottom: 20px;
    padding-bottom: 25px;
    overflow: hidden;
  }
  h2 {
    text-align: left;
    margin-bottom: 35px;
    a {
      text-decoration: none;
    }
  }
  h3 {
    font-size: 20px;
  }
  .featured-thumbnail {
    display: none;
  }
  .entry-content,
  .excerpt {
    overflow: hidden;
    margin-bottom: 10px;
  }
  .details {
    float: right;
  }
}

/* Contact form common styles.
------------------------------------------------------------ */
div.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0;
}

div.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
}

div.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
}

div.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
}

div.wpcf7-validation-errors {
  border: 2px solid #f7e700;
}

.wpcf7-display-none {
  display: none;
}

div.wpcf7 img.ajax-loader {
  border: none;
  vertical-align: middle;
  margin-left: 4px;
}

div.wpcf7 div.ajax-error {
  display: none;
}

div.wpcf7 .placeheld {
  color: #5a5a5a;
}

// Theme styles.
div.wpcf7 {

  // Reduce recaptcha size on mobile via hack.
  @include break($x-small) {
    .g-recaptcha {
      transform: scale(0.83);
      transform-origin: 0 0;
    }
  }

  .row > div {
    margin: 0;
    @include break($x-small) {
      width: 100%;
    }
  }

  // Placeholders.
  ::-webkit-input-placeholder {
    color: #555;
  }

  ::-moz-placeholder {
    color: #555;
  }

  :-ms-input-placeholder {
    color: #555;
  }

  p {
    padding: 0;
    @include clearfix();
  }
  br {
    display: none;
  }

  label {
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    display: block;
    color: #fff;
    padding-bottom: 7px;
    sup {
      color: #f04028;
    }
  }

  .wpcf7-form-control-wrap {
    display: block;
    position: relative;
    margin-bottom: 15px;
    // Captcha field wrapper.
    &.captcha {
      float: left;
      width: 146px;
      margin-right: 15px;
      span.wpcf7-not-valid-tip {
        /*background: none;
        font-size: 0;
        border: 1px solid red;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;*/
      }
    }
  }

  // Checkbox group.
  .wpcf7-checkbox {
    display: block;
    padding-top: 10px;
    @include clearfix();
    span.wpcf7-list-item {
      width: 50%;
      float: left;
      padding-bottom: 2px;
      @include break($x-small) {
        width: 100%;
      }
      span {
        position: relative;
        display: inline-block;
        padding: 0 0 0 30px;
        &::before {
          content: '';
          width: 14px;
          height: 14px;
          position: absolute;
          top: 2px;
          left: 0;
          border: 1px solid #d6d6d6;
          background: #fff;
          border-radius: 2px;
        }
      }
      input[type="checkbox"] {
        display: none;
        &:checked {
          + span {
            color: #000;
            &::before {
              border-color: transparent;
              background: $yellow;
            }
          }
        }
      }
    }
  }

  // Submit row.
  .submit-row {
    @include clearfix();
    > div {
      float: left;
    }
    > p {
      float: right;
    }
  }

  // Submit button.
  .wpcf7-submit {
    display: block;
    margin: 10px auto 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    clear: both;
  }

  .g-recaptcha {
    margin: 0 auto;
    @include break($medium) {
      //transform: scale(0.8);
      //transform-origin: 0 0;
    }
  }

  // Error message.
  span.wpcf7-not-valid-tip {
    padding-top: 5px;
    color: #f04028;
    display: block;
    font-size: 14px;
    position: absolute;
    top: 4px;
    right: 5px;
  }

  div.wpcf7-response-output {
    padding: 10px 20px;
    text-align: center;
    font-size: 13px;
    background: #fff;
    @include break($medium) {
      padding: 5px 10px;
    }
  }

  div.wpcf7-validation-errors {
    background: #fde8b1;
    color: #222;
    clear: both;
  }
  div.wpcf7-mail-sent-ok {
    background: #ccff99;
    border: 2px solid #398f14;
  }

  img.ajax-loader {
    display: block;
    background: #fff;
  }
}

/* Breadcrumbs.
------------------------------------------------------------ */
.breadcrumbs {
  @include font(14px, 1, #8c8c8c);
  padding: 15px 0;
  background: #fff;
  text-transform: uppercase;
  border-bottom: 1px solid #dadadb;
  @include break($mobile) {
    position: static;
  }
  // Separator arrow.
  span + i {
    margin: 0 12px;
  }
  a {
    color: inherit;
    text-decoration: none;
    @include break($mobile) {
    }
    &:hover,
    &:focus,
    &:active {
      color: $blue;
      text-decoration: underline;
    }
  }
}

/* Page title.
------------------------------------------------------------ */
.page-title-row {
  background-color: $purple;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: contain;
  .image-holder img {
    display: block;
    width: 100%;
  }
  .page-title {
    padding: 6% 0 6% $side_padding;
    position: relative;
    width: 40%;
    &::after {
      content: '';
      background: $purple;
      height: 100%;
      display: block;
      position: absolute;
      right: -50px;
      left: -200px;
      top: 0;
      pointer-events: none;
      transform: skewX(-40deg);
    }
    h1 {
      position: relative;
      z-index: 2;
      opacity: 0;
      transform: scale(.9);
      .loaded & {
        transition: all 0.3s ease-out;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  &.no-image {
    .page-title {
      width: 100%;
      &::after {
        display: none;
      }
    }
  }
}

/* Post formats -> standard.
----------------------------------------------------------- */
article.format-standard {
  overflow: hidden;
  .entry-header {
    h2 {
      a {
        color: inherit;
        &:hover {
          color: $color_hover;
        }
      }
    }
  }

  .featured-thumbnail {
    float: left;
    margin: 0 30px 0 0;
    line-height: 0;
    @include break($medium) {
      max-width: 40%;
    }
    @include break($neutral) {
      max-width: 100%;
      float: none;
      margin: 0 0 30px 0;
    }
    a {
      display: block;
      overflow: hidden;
      @include break($mobile) {
        display: inline-block;
      }
    }
  }

  .post-content {
    overflow: hidden;
    padding-bottom: 15px;
  }

}

/* Search form.
----------------------------------------------------------- */
.search-form {
  position: relative;
  max-width: 400px;
  .search-field {
    border: 1px solid #ccc;
  }
  input[type='submit'] {
    background: url("../images/icons/icon-search.png") no-repeat 50% 50%;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    width: 30px;
    height: 100%;
    transition: .3s;
    &:hover,
    &:active,
    &:focus {
      background: url("../images/icons/icon-search.png") no-repeat 50% 50%;
      opacity: .7;
    }
  }
}

/* -------------------------------------------------------------------------------------------------------------------
                                                  Sidebar section
------------------------------------------------------------------------------------------------------------------- */
.sidebar {
  position: relative;
  z-index: 1;
  .widget {
    margin-bottom: 30px;
    &.widget-last {
      margin-bottom: 0;
    }
  }
}

.sidebar-blog {
  padding: 30px 0;
}

.sidebar-right {
  padding: 0 0 0 10%;
  @include break($neutral) {
    padding-left: 0;
  }
  .widget-title {
    display: none;
  }
}

/* Categories widget.
------------------------------------------------------------ */
.widget_categories {
  background: #efefef;
  padding: 20px 0 25px;
  border-radius: 10px;
  .widget-title {
    @include font(20px, 1.4, #000, 700);
    text-align: center;
    font-family: $playfair;
    text-transform: uppercase;
    margin: 0;
    &::after {
      content: '';
      display: block;
      width: 77px;
      height: 3px;
      background: #d6d6d6;
      margin: 15px auto 25px;
    }
  }
  li {
    border-bottom: 1px solid #fff;
    a {
      @include font(16px, 1.5, #155eb0);
      text-decoration: none;
      display: block;
      padding: 7px 15px 7px 25px;
      &:hover {
        color: #00295b;
        background: #dedede;
      }
      span {
        font-weight: 400;
      }
    }
    &.current-cat,
    &.current-cat-parent,
    &.current-cat-ancestor {
      > a {
        color: #00295b;
        background: #dedede;
      }
    }
  }
  > ul {
    visibility: visible;
    > li {
      > a {
        font-weight: 500;
      }
    }
    .children {
      li {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 17px;
          left: 27px;
          width: 5px;
          height: 5px;
          background: #9f9f9f;
          border-radius: 50%;
          display: block;
        }
        a {
          padding: 7px 10px 7px 45px;
        }
      }
    }
  }
}

/* Video block.
----------------------------------------------------------- */
.content-video {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .7);
  border: 7px solid #797979;
  display: block;
  font-size: 0;
  line-height: 0;
  position: relative;
  border-radius: 4px;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  &:hover {
    border-color: #d6d6d6;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, .8);
    &::before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

/* Posts items.
------------------------------------------------------------ */
.posts-list {
  .post {
    margin-bottom: 25px;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 10px;
    a {
      //text-decoration: none;
    }
    .entry-title {
      @include font(30px, 1.3);
      text-transform: uppercase;
      a {
        text-decoration: none;
        color: #155eb0;
        &:hover {
          color: #00295b;
          text-decoration: underline;
        }
      }
    }
    .meta-info {
      padding: 14px 0;
      font-size: 14px;
      color: #555;
      .sep {
        padding: 0 7px;
      }
      a[rel="author"] {
        color: inherit;
        text-decoration: none;
      }
    }
    .post-content {
    }
    .excerpt {
      font-size: 16px;
      line-height: 1.5;
      padding-bottom: 10px;
    }
    .more-wrapper {
      text-align: right;
      .read-more {
        text-transform: uppercase;
        color: #155eb0;
        font-size: 16px;
        text-decoration: none;
        font-weight: 600;
        i {
          color: #999;
          margin-left: 3px;
        }
        &:hover {
          color: #00295b;
          text-decoration: underline;
          i {
            color: #0f0f0f;
          }
        }
      }
    }
  }
}