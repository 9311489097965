// ++++++++++++++++++++++++ Variables ++++++++++++++++++++++++

// Fonts.
$open_sans: 'Open Sans', sans-serif;
$playfair: 'Playfair Display', serif;

$font_awesome: FontAwesome;

// Breakpoints (required by break @mixin).
$large: large;
$desktop: desktop;
$medium: medium;
$neutral: neutral;
$mobile: mobile;
$not-mobile: not-mobile;
$x-small: x-small;

$side_padding: 5%;

// Colors.
$color_primary: #000;
$color_secondary: #000;

$red: #da261d;
$red_light: #f16379;
$pink: #c33657;
$green: #7a9378;
$blue: #155eb0 ;
$light_blue: #92d5ff;
$deep_blue: #00295b;
$orange: #fe7902;
$yellow: #ffcc00;
$purple: #451336;
$grey: #868a8b;
$grey_light: #e8e8e8;
$white_light: #f2f2f2;

$color_link: $purple;
$color_hover: $pink;

// ++++++++++++++++++++++++ Mixins ++++++++++++++++++++++++ */
// Consistent with bootstrap breakpoints.
@mixin break($point) {
	@if $point == 1600 {
		@media (max-width: 1600px) {
			@content;
		}
	}
	@if $point == large {
		@media (max-width: 1329px) {
			@content;
		}
	} @else if $point == desktop {
		@media (min-width: 1330px) {
			@content;
		}
	} @else if $point == medium {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point == neutral {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == not-mobile {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point == x-small {
		@media (max-width: 479px) {
			@content;
		}
	}
}

// Adds circles via styles(normally on ::before/::after).
@mixin circle($size: 10px, $color: #fff) {
	width: $size;
	height: $size;
	background: $color;
	border-radius: 50%;
	content: '';
	display: inline-block;
}

// Clear floating.
@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin font($font_size:24px, $line_height:1.3, $color:$color_primary, $font_weight: 400) {
	font-size: $font_size;
	line-height: $line_height;
	color: $color;
	font-weight: $font_weight;
}

// ++++++++++++++++++++++++ Animations ++++++++++++++++++++++++ */
//
@keyframes fadeOutText {
	0% {
		color: transparent;
	}
	80% {
		color: transparent;
	}
	100% {
		color: #fff;
	}
}

// Spin animation
.fa-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes fillWidth {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}
