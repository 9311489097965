/* -------------------------------------------------------------------------------------------------------------------
                                                  Header elements
------------------------------------------------------------------------------------------------------------------- */
.site-header {
  overflow: hidden;
  position: relative;
  height: 710px;
  background-repeat: no-repeat;
  //background-position: 0 100%;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  /*position: absolute;
  height: 200%;
  top: -120%;
  left: 0;
  width: 100%;
  bottom: 0;*/
  @media (min-width: 1900px) {
    height: 760px;
  }
  @include break($large) {
    height: 640px;
  }
  @include break($medium) {
    height: 480px;
  }
  @include break($mobile) {
    height: auto;
    background: none !important;
  }
  .header-wrapper {

  }
  .touch & {
    background-attachment: scroll;
  }
  p {
    padding: 0;
  }

  .site-logo {
    display: table-cell;
    vertical-align: middle;
    @include break($mobile) {
      text-align: center;
      display: block;
      padding: 10px 20px;
      width: auto;
    }
    > a {
      display: block;
      text-decoration: none;
      img {
        @include break($large) {
          width: 200px;
        }
        @include break($mobile) {
          width: auto;
        }
      }
    }
  }

  // Logo & text blocks.
  .row-top {
    background: rgba(0, 0, 0, 0.75);
    padding: 10px 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include clearfix();
    .container {
      display: table;
    }
    @include break($mobile) {
      padding-top: 110px;
      position: static;
      background: none;
      text-align: center;
    }
  }

  /*.header-blocks {
      overflow: hidden;
  }*/

  // Phone widget.
  .widget.phone {
    display: table-cell;
    vertical-align: middle;
    @include font(22px, 1.1, #fff, 600);
    opacity: 0;
    padding: 18px 0 0 0;
    transform: translateX(50px);
    text-align: right;
    .loaded & {
      transition: all 0.3s ease-out .3s;
      opacity: 1;
      transform: translateX(0);
    }
    @include break($large) {
      font-size: 16px;
      //padding-top: 7px;
    }
    @include break($neutral) {
    }
    @include break($mobile) {
      display: none;
    }
    a {
      display: block;
      font-size: 1.8em;
      color: inherit;
      text-decoration: none;
      @include break($medium) {
      }
      @include break($neutral) {
      }
    }
  }

  // Header text.
  .widget.header-text {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    @include font(22px, 1.2, #fff, 600);
    opacity: 0;
    transform: translate(-50%, -50%);
    .loaded & {
      transition: all 0.3s ease-out .15s;
      opacity: 1;
    }
    @include break($large) {
      font-size: 16px;
      padding-top: 12px;
    }
    @include break($neutral) {
    }
    @include break($mobile) {
      display: none;
    }
  }

  // Bottom row elements.
  .row-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .container {
      position: relative;
    }
    .header_slogan {
      position: absolute;
      left: 40px;
      width: 39%;
      bottom: 1em;
      font-family: $playfair;
      @include font(100px, 0.8, #fff);
      text-transform: uppercase;
      text-shadow: 3px 3px 2px rgba(0, 0, 0, .5);
      word-spacing: 200px;
      transform: translateX(-50px);
      opacity: 0;
      transition: all .5s ease .3s;
      .loaded & {
        opacity: 1;
        transform: translateX(0);
      }
      @include break($large) {
        font-size: 85px;
        bottom: 170px;
        left: 30px;
      }
      @include break($medium) {
        font-size: 70px;
        bottom: 100px;
      }
      @include break($neutral) {
        font-size: 50px;
        bottom: 130px;
      }
    }
    .header_photo {
      display: block;
      position: absolute;
      bottom: 0;
      left: 51%;
      transform: translate(-50%, -30px);
      z-index: 1;
      transform-origin: 50% 100%;
      opacity: 0;
      transition: all .5s ease .4s;
      .loaded & {
        opacity: 1;
        transform: translate(-50%, 0);
      }
      .page-id-11 & {
        left: 70%;
      }
      @include break($medium) {
        height: 340px;
      }
      @include break($neutral) {
        left: 49%;
      }
    }

    // Contact form.
    .wpcf7 {
      position: absolute;
      right: 15px;
      bottom: 0;
      background: rgba(0, 0, 0, 0.75);
      border-radius: 10px 10px 0 0;
      padding: 30px 40px 10px;
      width: 410px;
      transform-origin: 100% 100%;
      @include break($medium) {
        transform: scale(.7);
      }
      @include break($neutral) {
        width: 380px;
      }
      .title {
        text-align: center;
        padding-bottom: 10px;
        div {
          text-transform: uppercase;
          @include font(26px, 1, #fff, 800);
        }
        span {
          @include font(18px, 1, #fff, 700);
        }
      }

      .wpcf7-form-control-wrap {
        margin-bottom: 7px;
      }
      div.wpcf7-validation-errors {
        display: none !important;
      }
      div.wpcf7-mail-sent-ok {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      // Errors.
      span.wpcf7-not-valid-tip {

      }
    }
  }

}

/* Primary menu.
------------------------------------------------------------ */
.row-menu {
  background: $blue;
  position: absolute;
  top: 139px;
  width: 100%;
  z-index: 10;
  &.sticky {
    position: fixed;
    top: 0;
  }
  .admin-bar & {
    position: absolute;
  }
}

.header-menu {
  position: relative;
  z-index: 6;
  // Common styles.
  .main-menu {
    display: flex;
    li {
      display: block;
      z-index: 2;
      position: relative;
      a {
        text-decoration: none;
        position: relative;
        display: block;
      }
    }
    // 1st level menus.
    > li {
      //float: left;
      flex: auto;
      text-align: center;
      border-right: 1px solid #165194;
      &:first-child {
        border-left: 1px solid #165194;
      }
      &:last-child {
        a {
        }
      }
      > a {
        @include font(18px, 45px, #fff, 700);
        //padding: 0 42px;
        letter-spacing: 0.035em;
        text-transform: uppercase;
        @include break($large) {
          font-size: 15px;
          //line-height: 40px;
        }
        @include break($medium) {
          //padding: 0 23px;
          line-height: 30px;
        }
        @include break($neutral) {
          text-transform: capitalize;
          //padding: 0 11px;
          font-size: 14px;
        }
        &.sf-with-ul {
        }
        // Hover root item.
        &:hover,
        &:focus {
          color: #fff;
          background: $deep_blue;
          &::after {
          }
        }
      }

      // Parent root menu item.
      &.menu-item-has-children {
        // Keep activity for the root item while viewing dropdown.
        &.sfHover > a {
          color: #fff;
          background: $deep_blue;
          &::after {
          }
        }
      }

      // Active root menu item.
      &.current_page_item,
      &.current-menu-item,
      &.current-page-ancestor,
      &.current-menu-ancestor {
        > a {
          background: #165194;
          &::after {
          }
        }
      }

      // Animate dropdown menu.
      &.sfHover,
      &:hover {
        > ul.sub-menu {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }

      // 2nd Level.
      ul.sub-menu {
        background: rgba(0, 0, 0, .9);
        padding: 10px 30px;
        display: none;
        position: absolute;
        top: 45px;
        left: 0;
        z-index: 2;
        //width: 240px;
        width: 285px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s ease-out;
        transform: translateY(30px);
        @include break($large) {
          top: 30px;
          width: 300px;
          padding: 10px 20px;
        }
        > li {
          border-bottom: 1px solid #292422;
          &:last-child {
            border: none;
          }
          // Current sub menu item.
          &.current_page_item > a,
          &.current-menu-ancestor > a,
          &.current-menu-item > a {
            color: #fff;
          }
          // Animate dropdown sub menu.
          &.sfHover,
          &:hover {
            > ul.sub-menu {
              transform: translateY(0);
              opacity: 1;
              visibility: visible;
            }
          }
          > a {
            padding: 7px 0;
            color: #c5c5c5;
            font-size: 18px;
            font-weight: 700;
            -webkit-transform: scale(1) translateZ(0);
            @include break($large) {
              font-size: 15px;
              white-space: nowrap;
            }
            @include break($medium) {
              font-size: 14px;
            }
            @include break($neutral) {
            }
            // Hover sub menu item.
            &:hover {
              color: #fcfcfc;
              font-weight: 700;
            }
          }
        }
        // 3rd level.
        ul.sub-menu {
          //left: 210px;
          left: 255px;
          top: -10px;
          background: rgba(0, 0, 0, .8);
          @include break($neutral) {
            width: 190px;
          }
          @include break($large) {
            left: 280px;
          }
        }
      }
    }
  }

}

/* Mobile menu toggle icon.
------------------------------------------------------------ */
.mobile-menu-icon {
  display: none;
  color: #fff;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 15px;
  width: 50px;
  padding: 30px 0 0 0;
  background: url("../images/icon-mobile-menu.gif") no-repeat 50% 0;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
  @include break($mobile) {
    display: block;
  }
  .mm-opened & {

  }
}

/* Mobile menu theme overrides.
------------------------------------------------------------ */
.mm-menu {
  color: $orange;

  .mm-listview {

    // Next arrow
    .mm-next {
      background: $orange;
    }

    // Menu item.
    li a,
    li span {
      color: $blue;
      padding: 8px 10px 8px 20px;
      -webkit-tap-highlight-color: $orange;
      //font-size: 16px;
    }
    // Next arrow color.
    > li > a.mm-prev::before,
    > li > a.mm-next::after {
      border-color: #fff;
    }
  }
  // Active item.
  li.current-menu-ancestor > a,
  li.current_page_item > a,
  li.current-menu-item > a {
    color: $orange;
    font-weight: bold;
  }

  // Header menu item.
  .mm-header {
    > a {
      color: $blue;
      font-size: 16px;
    }
    // Slide back arrow.
    .mm-btn {
      background: $blue;
      &::before,
      &::after {
        border-color: #fff;
      }
    }
  }

}

// Fixed scrolling row on mobile.
.row-mobile {
  position: fixed;
  //height: 38px;
  background: #05050d;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  .header-phone {
    float: right;
    text-align: right;
    font-style: italic;
    padding: 9px 12px 9px 0;
    color: #fff;
    strong {
      font-size: 24px;
      font-style: normal;
    }
  }
}

/* Mobile elements.
------------------------------------------------------------ */
.row-mobile-elements {
  height: 100%;
  .row-background {
    padding-top: 30px;
    margin-top: 69px;
    background: url("../images/header-bg-mobile.jpg") no-repeat 50% 0/cover;
    height: 100%;
  }
  // Header text for mobile.
  .header-text-mobile {
    text-align: center;
    @include font(16px, 1.5, #fff, 600);
    width: 80%;
    margin: auto;
    br {
      display: none;
    }
  }

  .row-middle {
    position: relative;
    overflow: hidden;
    .header_photo {
      float: right;
      height: 330px;
    }
    .header_slogan {
      position: absolute;
      left: 25px;
      width: 50%;
      bottom: 20%;
      font-family: $playfair;
      @include font(65px, 0.8, #fff);
      text-transform: uppercase;
      text-shadow: 3px 3px 2px rgba(0, 0, 0, .5);
      word-spacing: 200px;
      transform: translateX(-50px);
      opacity: 0;
      transition: all .5s ease .3s;
      .loaded & {
        opacity: 1;
        transform: translateX(0);
      }
      @include break($x-small) {
        font-size: 50px;
      }
    }
  }
  // Contact form.
  .wpcf7 {
    clear: both;
    padding: 30px 40px 10px;
    background: #000;
    .title {
      text-align: center;
      padding-bottom: 10px;
      div {
        text-transform: uppercase;
        @include font(26px, 1, #fff, 800);
        @include break($x-small) {
          font-size: 20px;
        }
      }
      span {
        @include font(18px, 1, #fff, 700);
        @include break($x-small) {
          font-size: 14px;
        }
      }
    }
    .wpcf7-form-control-wrap {
      margin-bottom: 7px;
    }
    // Errors.
    span.wpcf7-not-valid-tip {
      padding-top: 5px;
      font-size: 14px;
      position: absolute;
      top: 4px;
      right: 5px;
    }
  }
}
