/* Slider header theme.
------------------------------------------------------------ */
// Fix for height on page load.
.main-slider {
	.slides {
		img {
			/*width: 1800px;
			display: block;
			-moz-user-select: none;
			height: 848px;
			position: relative;
			margin-left: -900px;
			left: 50%;
			top: 50%;
			margin-top: -424px;
			max-width: 3000px;
			@media all and (min-width: 1800px) {
				width: 100%;
				height: auto;
				margin: 0;
				position: static;
			}
			@include break($mobile) {
				width: 100%;
				display: block;
				min-height: 100px;
				margin: 0;
				position: static;
			}*/
		}
	}
}

.metaslider {
	position: relative;
	.caption-wrap {
		background: $orange;
		@include break($mobile) {
		}
		.caption {
			color: #fff;
			font-size: 24px;
			padding-top: 1.16em;
			opacity: 0;
			height: 131px;
			transition: opacity 0.5s ease-out 0.5s;
			@include break($medium) {
				font-size: 17px;
				height: 91px;
			}
			@include break($mobile) {
				height: auto;
				padding: 20px 10px;
				font-size: 14px;
			}
			.inner {
				width: 67%;
				@include break($neutral) {
					width: 100%;
				}
			}
			h1 {
				font-style: italic;
				font-size: 1.70em;
				font-weight: 700;
				padding-bottom: 5px;
				color: #fff;
				@include break($mobile) {
					font-size: 1.3em;
				}
			}
		}
	}

	// Animate current slide caption.
	.flex-active-slide .caption {
		opacity: 1;
	}

	.slides {
		> li {
			overflow: hidden;
			position: relative;
		}
	}

	// Left/right arrows.
	.flex-direction-nav {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 2;
		@include break($medium) {
			transform: scale(0.6);
			transform-origin: 100% 100%;
		}
		@include break($mobile) {
			//display: none;
		}
		li {
			display: inline;
		}
		a {
			color: #fff;
			display: block;
			float: left;
			font-size: 0;
			height: 82px;
			line-height: 0;
			text-align: center;
			text-decoration: none;
			transition: all 0.3s ease 0s;
			width: 82px;
			z-index: 12;
			opacity: .6;
			&:hover {
				opacity: 01;
			}
			&.flex-prev {
				background: #000 url("../images/slider-prev.png") 50% 50% no-repeat;
			}
			&.flex-next {
				background: #000 url("../images/slider-next.png") 50% 50% no-repeat;
			}
		}
	}

	// Slider hover.
	.non-touch &:hover {
		.flex-direction-nav {
			display: block;
		}
	}
}
