/* -------------------------------------------------------------------------------------------------------------------
                                                  Footer
------------------------------------------------------------------------------------------------------------------- */
.site-footer {

  .row-footer-blocks {
    padding: 3% 0 2%;
    background: #f5f5f5;
    line-height: 1.5;
    @include clearfix();
    @include break($neutral) {
    }
    @include break($mobile) {
      padding: 20px 0;
      text-align: center;
    }

    p {
      padding-bottom: 5px;
    }

    // Attorney info.
    .widget.author {
      float: left;
      font-size: 16px;
      font-weight: 600;
      width: 34%;
      @include break($medium) {
        font-size: 15px;
      }
      @include break($mobile) {
        font-size: 14px;
        width: 100%;
        float: none;
        padding: 0 0 10px;
      }
      strong {
        font-family: $playfair;
        @include font(34px, 1);
        display: block;
        padding-bottom: 10px;

        @include break($medium) {
          font-size: 30px;
        }
        @include break($mobile) {
          font-size: 25px;
        }
      }
    }

    // General info.
    .widget.info {
      float: right;
      width: 61%;
      font-size: 14px;
      font-weight: 700;
      text-align: justify;
      @include break($mobile) {
        float: none;
        width: 100%;
      }
    }

  }

  .row-copyright {
    background: #15151b;
    padding: 20px 0;
    @include clearfix();
    p {
      padding: 0;
    }
    a {
      color: #fff;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .block-copyright {
      @include font(12px, 1.7, #929292);
      float: left;
    }
  }

}