/* Main Theme Styles file */
// Major components.
@import "reset";
@import "variables";
@import "functional";
@import "header";
@import "footer";
@import "slider_theme";
//@import "owl.carousel.core";
@import "magnific_popup";
@import "elements";

/* -------------------------------------------------------------------------------------------------------------------
                                                  Custom blocks & decor
------------------------------------------------------------------------------------------------------------------- */

/* Featured text block.
------------------------------------------------------------ */
.row-featured-text {
  text-align: center;
  background: #efefef;
  font-size: 24px;
  .container {
    background: #efefef url("../images/text-bg.jpg") no-repeat 50% 50%;
    background-size: contain;
    padding-top: 5%;
    padding-bottom: 5%;
    @include break($mobile) {
      background: none;
    }
  }
  @include break($medium) {
    font-size: 20px;
  }
  @include break($mobile) {
    font-size: 18px;
  }
  @include break($x-small) {
    font-size: 16px;
  }
  .not-home & {
    font-size: 22px;
    @include break($medium) {
      font-size: 20px;
    }
    @include break($mobile) {
      font-size: 18px;
    }
    h4 {
      @include font(26px, 1.3, $blue, 700);
      font-family: $playfair;
      @include break($medium) {
        font-size: 22px;
      }
      @include break($mobile) {
        font-size: 18px;
      }
      @include break($x-small) {
        font-size: 17px;
      }
    }
  }
}

/* Parallax block.
------------------------------------------------------------ */
.row-parallax {
  padding: 4% 0 0 0;
  color: #fff;
  .text {
    text-align: center;
    font-family: $playfair;
    @include font(40px, 1.1, #fff);
    text-shadow: 2px 2px 3px rgb(0, 0, 0);
    padding-bottom: 1em;
    @include break($medium) {
      font-size: 34px;
    }
    @include break($mobile) {
      font-size: 26px;
    }
  }
  ul li {
    color: #fff;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, .8);
  }
  .block-overlay {
    background: rgba(26, 26, 29, 0.7);
    padding: 40px 0 30px;
    margin-top: 45px;
    text-align: center;
    font-size: 26px;
    color: #fff;
    @include break($medium) {
      font-size: 23px;
    }
    @include break($mobile) {
      font-size: 20px;
    }
    @include break($x-small) {
      font-size: 18px;
    }
    a.btn {
      margin-top: 10px;
    }
  }
  .col-md-4 {
    text-align: center;
    @include break($neutral) {
      margin-top: 20px;
    }
  }
  .more {
    padding-left: 12px;
  }
}

/* Image linked to the youtube video.
------------------------------------------------------------ */
.video-link {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .7);
  border: 7px solid #797979;
  display: inline-block;
  font-size: 0;
  line-height: 0;
  position: relative;
  border-radius: 4px;
  &::before {
    content: "";
    background: url("../images/icon-youtube.png");
    width: 88px;
    height: 61px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.8);
    transition: .3s;
  }
  &:hover {
    border-color: #d6d6d6;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, .8);
    &::before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

/* Practice areas section.
------------------------------------------------------------ */
.row-practice-areas {
  padding: 3% 0;
  background: #fff;
  text-align: center;
  font-size: 0;
  .row {
    margin: 10px -1px;
  }
  .block {
    width: 25%;
    float: left;
    background: #efefef;
    @include font(22px, 1.3, $color_primary, 600);
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: .3s;
    @include break($medium) {
      width: 33.3%;
    }
    @include break($neutral) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 100%;
      font-size: 18px;
      &.linked {
        //height: 120px;
      }
    }
    p {
      padding: 0;
    }
    img {
      display: block;
      width: 100%;
      transition: .3s;
    }
    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-decoration: none;
      pointer-events: none;
      .touch & {
        background: #383a3f;
        font-size: 0;
      }
      @include break($mobile) {
        background: #383a3f;
        font-size: 0;
      }
      em {
        @include vertical-align();
        display: block;
        font-style: normal;
        strong {
          display: block;
          position: relative;
          font-weight: 600;
          padding-bottom: 15px;
          margin-bottom: 13px;
          &::after {
            width: 125px;
            height: 1px;
            content: "";
            left: 50%;
            bottom: 0;
            position: absolute;
            background: #d6d6d6;
            margin-left: -62px;
          }
        }
      }
      .text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

    }
    &.linked {
      &:hover {
        background: #383a3f;
      }
      ul {
        position: relative;
        top: 50%;
        transform: translateY(-50%) scale(0);
        transition: .5s;
        padding: 25px 0;
        .touch & {
          transform: translateY(-50%) scale(1);
        }
        @include break($mobile) {
          transform: translateY(-50%) scale(1);
        }
        @media (max-width: 600px) {
          .touch & {
            transform: translateY(0) scale(1);
            position: static;
            top: 0;
            padding: 25px 10px;
          }
        }
        li {
          position: relative;
          padding: 12px 0;
          background: none;
          &::before {
            width: 125px;
            height: 1px;
            content: "";
            left: 50%;
            top: 0;
            position: absolute;
            background: #747474;
            margin-left: -62px;
          }
          &:first-child {
            &::before {
              display: none;
            }
          }
        }
        a {
          position: relative;
          pointer-events: auto;
          @include font(18px, 1.3, #fff, 600);
          text-transform: uppercase;
          &:hover {
            color: #fe7902;
          }
        }
      }
      &.without-list {
        a {
          background: #efefef;
          font-size: 18px;
        }
        &:hover {
          em {
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
    &:hover {
      em {
        opacity: 0;
      }
      ul {
        transform: translateY(-50%) scale(1);
      }
    }
  }
}

/* -------------------------------------------------------------------------------------------------------------------
                                                  Pages & Content types
------------------------------------------------------------------------------------------------------------------- */
/* Practice areas page.
------------------------------------------------------------ */
.practice-areas-list {
  overflow: hidden;
  .item {
    margin: 0 0 30px;
    @include break($x-small) {
      width: 100%;
    }
    a {
      display: block;
      position: relative;
      text-decoration: none;
      h3 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        @include font(22px, 1.5, #fff, 600);
        font-family: $open_sans;
        text-transform: capitalize;
        text-align: center;
        padding: 16px 0;
        background: rgba(0, 0, 0, .9);
        margin: 0;
      }
      .featured-thumbnail {
        img {
          display: block;
          width: 100%;
        }
      }
      &:hover {
        h3 {
          background: $orange;
        }
      }
    }
  }
}

/* Single practice area page.
------------------------------------------------------------ */
.single-practice_areas {
  .featured-thumbnail {
    display: none;
  }
  h4 {
    text-align: center;
    text-transform: uppercase;
  }
  article {
    .content > .container {
      padding-top: 4%;
      padding-bottom: 6%;
    }
  }
}

/* Page blog & category & single post.
------------------------------------------------------------ */
.page-template-page-blog,
.archive,
.single-post {
  .site-header {
    height: 510px;
    @include break($mobile) {
      height: 330px;
    }
  }
  .page-title {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 1em;
    font-family: "Playfair Display", serif;
    font-size: 100px;
    text-align: center;
    line-height: .8;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
    word-spacing: 20px;
    //transform: translateX(-50px);
    opacity: 1;
    transition: all .5s ease .3s;
  }
  h2 {
    text-transform: uppercase;
    font-size: 36px;
    text-align: left;
    &::after {
      margin: 15px 0 25px;
    }
  }
}

/* Single post.
------------------------------------------------------------ */
.single-post {
  .entry-content {
    font-size: 16px;
    line-height: 1.5;
    a {
      color: #155eb0;
      &:hover {
        color: #00295b;
      }
    }
  }
  .categories {
    background: #efefef;
    padding: 10px 15px;
    text-transform: uppercase;
    margin: 20px 0 30px;
    font-size: 14px;
    color: #000;
    a {
      color: #155eb0;
      text-decoration: none;
      &:hover {
        color: #00295b;
      }
    }
  }
}

/* Contact page.
------------------------------------------------------------ */
.page-contact {
  .block-map iframe {
    width: 100%;
    background: url("../images/rolling.svg") no-repeat 50% 50%;
    max-height: 410px;
    @include break($mobile) {
      max-height: 250px;
    }
  }
}

/* Contact form inside main column (Free case review page).
------------------------------------------------------------ */
.content_form {
  .wpcf7 {
    position: relative;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 30px 40px 10px;
    max-width: 400px;
    transform-origin: 100% 100%;
    margin: 0 auto;
    .title {
      text-align: center;
      padding-bottom: 10px;
      > div {
        text-transform: uppercase;
        @include font(26px, 1, #fff, 800);
        @include break($medium) {
          font-size: 22px;
        }
        @include break($neutral) {
          font-size: 18px;
        }
      }
      span {
        @include font(18px, 1, #fff, 700);
        @include break($medium) {
          font-size: 16px;
        }
        @include break($neutral) {
          font-size: 13px;
        }
      }
    }
  }
}

/* Content on the contact page.
------------------------------------------------------------ */
.contact-page {
  [class*="col"] {
    padding-top: 27px;
    padding-bottom: 27px;
    @include break($medium) {
      font-size: 16px;
    }
    @include break($neutral) {
      font-size: 14px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    @include break($mobile) {
      margin: 0;
    }
    @include break($x-small) {
      width: 100%;
    }
  }
  p {
    padding: 0;
  }
  em[class*="icon"] {
    background: #155eb0;
    display: block;
    position: relative;
    width: 82px;
    height: 82px;
    float: left;
    margin: 0 26px 0 0;
    font-size: 0;
    text-align: center;
    @include break(neutral) {
      width: 54px;
      height: 54px;
      margin-right: 13px;
    }
    &::before {
      content: "\f022";
      font-family: $font_awesome;
      font-size: 50px;
      line-height: 82px;
      color: #fff;
      font-style: normal;
      @include break(neutral) {
        font-size: 27px;
        line-height: 54px;
      }
    }
    &.icon2 {
      &::before {
        content: "\f0e6";
      }
    }
    &.icon3 {
      &::before {
        content: "\f095";
      }
    }
    &.icon4 {
      &::before {
        content: "\f0e0";
      }
    }
    &.icon5 {
      &::before {
        content: "\f1ac";
      }
    }
    &.icon6 {
      &::before {
        content: "\f064";
      }
    }
  }
  .text {
    display: table;
  }
  strong {
    a {
      color: #155eb0;
    }
    &.ph {
      a {
        color: #000;
        text-decoration: none;
      }
    }
  }
  .clear-box {
    clear: both;
    @include break(mobile) {
      clear: none;
    }
  }
}

/* Bottom section.
------------------------------------------------------------ */
.row-bottom-text {
  padding: 3% 0;
  div[class*="col-"] + div {
    text-align: center;
    @include break($neutral) {
      margin-top: 20px;
    }
  }
  .block {
    text-align: center;
    /*float: right;
    width: 75%;*/
    color: #fff;
    font-size: 26px;
    @include break($medium) {
      font-size: 23px;
    }
    @include break($neutral) {
      width: 100%;
    }
    @include break($mobile) {
      font-size: 20px;
    }
    @include break($x-small) {
      font-size: 18px;
    }
    .btn {
      margin-top: 10px;
    }
  }
}

/* Text blocks.
------------------------------------------------------------ */
.text-block {
  background-repeat: no-repeat;
  background-size: cover;
  &.background-static {
    background-position: 50% 50%;
  }
  &.background-parallax {
    background-position: 50% 0;
    background-attachment: fixed;
  }
  .touch & {
    background-attachment: scroll;
  }
}

/* Contact form inside sidebar (Blog pages).
------------------------------------------------------------ */
.widget-form {
  .widget-title {
    display: none;
  }
  .wpcf7 {
    position: relative;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 30px 15px 25px;
    .title {
      text-align: center;
      padding-bottom: 10px;
      > div {
        text-transform: uppercase;
        @include font(22px, 1, #fff, 800);
        @include break($medium) {
          font-size: 20px;
        }
        @include break($neutral) {
          font-size: 18px;
        }
      }
      span {
        @include font(16px, 1, #fff, 700);
        @include break($medium) {
          font-size: 14px;
        }
        @include break($neutral) {
          font-size: 13px;
        }
      }
    }
    .wpcf7-submit {
      width: 100%;
    }
  }
}